import * as React from 'react';

import { CityImage } from '@src/components/city-image/city-image';
import { useParams } from 'react-router-dom';
import { Header } from '@src/components/header/header';
import { SitesGalleryWithModal } from '@src/components/sites-gallery/sites-gallery-with-modal';
import { ModalRootProvider } from '@src/modal-root-context';
import { useQuery } from '@tanstack/react-query';
import { fetchSitesForCity } from '@src/queries/sites-queries';

import { fetchCity } from '@src/queries/city-queries';
import styles from './city-view.module.scss';

interface CityViewRouteParams extends Record<string, string> {
  cityName: string;
}

export const CityView: React.FC = () => {
  const { cityName } = useParams<CityViewRouteParams>();

  const { data: city } = useQuery(['city', cityName], () => fetchCity(cityName));
  const { data: sites } = useQuery(['sites', cityName], () => fetchSitesForCity(cityName));

  return (
    <ModalRootProvider href={`/${cityName}`}>
      <div className={styles.cityView}>
        <Header />

        <div className={styles.contentContainer}>
          {city?.picture && <CityImage city={city} imageClass={styles.mainImage} />}

          <SitesGalleryWithModal sites={sites} />
        </div>
      </div>
    </ModalRootProvider>
  );
};
