import * as React from 'react';
import './image-overlay.scss';
import clsx from 'clsx';
import { HasClassName } from '@src/commons/common-types';
import { Site } from '../../model';

interface Props extends HasClassName {
  site: Site;
}

export const ImageOverlay: React.FC<Props> = ({ site, className }) => (
  <div className={clsx('image-overlay', className)}>
    <div className="site-name">{site.primaryName}</div>
    {/* TODO: why was it needed? remove? */}
    {/* &nbsp;<div>{site.shortDescription}</div> */}
  </div>
);
