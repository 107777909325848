import * as Noty from 'noty';

import 'noty/lib/noty.css';
import 'noty/lib/themes/relax.css';

export const showNotification = (text: string) => {
  new Noty({
    layout: 'topCenter',
    progressBar: false,
    theme: 'relax',
    timeout: 2000,
    text,
  }).show();
};
