import API from '@aws-amplify/api';
import { PRIVATE_API_NAME } from '@src/aws/amplify';

export function getFromApi(path: string) {
  return API.get(PRIVATE_API_NAME, path, {});
}

export function postToApi(path: string, payload: object) {
  return API.post(PRIVATE_API_NAME, path, payload);
}
