import * as React from 'react';

import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '@src/reducers';
import { UserIcon } from '@src/components';
import { signOut } from '@src/actions/auth/actions';
import { HeaderMenu } from '@src/components/header/header-menu';
import { useAuth } from '@src/auth';
import { useGoogleAuth } from '@src/auth/google-auth-context';
import { showNotification } from '@src/commons/notifications';

import styles from '../header-menu/header-menu.module.scss';

export const UserHeaderMenu: React.FC = () => {
  const { username } = useAuth();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const googleAuth = useGoogleAuth();

  const onLogOutIsClicked = useCallback(async () => {
    await dispatch(signOut(googleAuth));
    navigate('/');
    showNotification('Logged out!');
  }, [dispatch, navigate, googleAuth]);

  if (!username) {
    return null;
  }

  return (
    <HeaderMenu ClickableIcon={UserIcon}>
      <Link className={styles.headerMenuInnerLink} to="/favorites">
        <FontAwesomeIcon className={styles.headerMenuInnerLinkIcon} icon={faHeart} />
        Favorites
      </Link>

      <div className={styles.headerMenuInnerPanel}>
        <Link className={styles.headerMenuInnerPanelLink} onClick={onLogOutIsClicked} to="/">
          <FontAwesomeIcon className={styles.headerMenuInnerPanelLinkIcon} icon={faSignOut} />
          Log out
        </Link>
      </div>
    </HeaderMenu>
  );
};
