import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import './site-submission-view.scss';

import {
  SiteSubmissionForm,
  uploadSiteSubmissionFormS3,
} from '@src/actions/site-submission/actions';
import { PageWithHeader } from '@src/components/page-with-header/page-with-header';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';
import { AppDispatch } from '@src/reducers';
import { showNotification } from '@src/commons/notifications';

const initialValues: SiteSubmissionForm = {
  siteName: '',
  city: '',
  photo: null,
  siteDescription: '',
  photoDescription: '',
  email: '',
  website: '',
  author: '',
};

export const SiteSubmissionView: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const onFormSubmit = useCallback(
    (values: SiteSubmissionForm, { resetForm }) => {
      showNotification('Place submitted!');
      dispatch(uploadSiteSubmissionFormS3(values));
      resetForm();
    },
    [dispatch],
  );

  return (
    <div className="site-submission-view">
      <PageWithHeader>
        <div className="site-submission-view-container">
          <div className="site-submission-form-container">
            <h3>Submit new site</h3>

            <Formik
              initialValues={initialValues}
              validate={(values) => {
                const errors: SiteSubmissionForm = {};
                if (!values.siteName) {
                  errors.siteName = 'Required';
                } else if (!values.city) {
                  errors.city = 'Required';
                } else if (!values.email) {
                  errors.email = 'Required';
                } else if (!values.author) {
                  errors.author = 'Required';
                }
                return errors;
              }}
              onSubmit={onFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <form className="site-submission-form" onSubmit={handleSubmit}>
                  <div className="field is-horizontal">
                    <div className="field-label">
                      <label className="label">Place *</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            className="input"
                            name="siteName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.siteName}
                          />
                        </div>
                        {errors.siteName && touched.siteName && (
                          <div className="help">{errors.siteName}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label">
                      <label className="label">City *</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            name="city"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.city}
                            className="input"
                          />
                        </div>
                        {errors.city && touched.city && <div className="help">{errors.city}</div>}
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label">
                      <label className="label">Site photo *</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <div className="file">
                            <label className="file-label">
                              <input
                                name="photo"
                                type="file"
                                onChange={(event) => {
                                  setFieldValue('photo', event.currentTarget.files[0]);
                                }}
                                className="file-input"
                              />
                              <span className="file-cta">
                                <span className="file-icon">
                                  <FontAwesomeIcon icon={faUpload} />
                                </span>
                                <span className="file-label">
                                  {values.photo ? values.photo.name : 'Choose a file…'}
                                </span>
                              </span>
                            </label>
                          </div>
                        </div>
                        {errors.photo && touched.photo && (
                          <div className="help">{errors.photo.name}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label">
                      <label className="label">Place description</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <textarea
                            name="siteDescription"
                            value={values.siteDescription}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="textarea"
                            rows={3}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label">
                      <label className="label">Photo description</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <textarea
                            placeholder="Photo description (optional)"
                            name="photoDescription"
                            value={values.photoDescription}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="textarea"
                            rows={3}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label">
                      <label className="label">Author *</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            placeholder="Your name"
                            name="author"
                            value={values.author}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="input"
                          />
                        </div>
                        {errors.author && touched.author && (
                          <div className="help">{errors.author}</div>
                        )}
                      </div>
                      <div className="field">
                        <div className="control">
                          <input
                            placeholder="Your contact e-mail"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="input"
                          />
                        </div>
                        {errors.email && touched.email && (
                          <div className="help">{errors.email}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label">
                      <label className="label">Your website</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            placeholder="Type your blog or website URL here"
                            name="website"
                            value={values.website}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label" />
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <button className="button is-medium is-dark" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </PageWithHeader>
    </div>
  );
};
