import * as React from 'react';

import { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { SchemaOf } from 'yup';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';

import { signIn } from '@src/actions/auth/actions';
import { GoogleButton } from '@src/components/google-button/google-button';
import { AppDispatch } from '@src/reducers';
import { BasicViewContainer } from '@src/components/basic-view-container';

import loginBackground from '@resources/images/login-inspiration.jpg';
import styles from './login-view.module.scss';

interface LoginForm {
  email: string;
  password: string;
}

const formValidationSchema: SchemaOf<LoginForm> = Yup.object().shape({
  email: Yup.string().email('E-mail is not valid!').required('E-mail is required!'),
  password: Yup.string().required('Password is required!'),
});

const formInitialValues: LoginForm = {
  email: '',
  password: '',
};

export const LoginView: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [message, setMessage] = useState('');
  const [isMessageError, setMessageError] = useState(false);

  const onFormSubmit = useCallback(
    async ({ email, password }: LoginForm) => {
      try {
        await dispatch(signIn(email, password));
        navigate('/');
      } catch (err) {
        setMessage(err.message);
        setMessageError(true);
      }
    },
    [dispatch, navigate],
  );

  return (
    <BasicViewContainer heroImageUrl={loginBackground}>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={onFormSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form className={styles.loginForm} onSubmit={handleSubmit}>
            <div className={styles.loginToTripspirationsLabel}>Log in to Tripspirations</div>
            <div className={clsx('message', { 'message-error': isMessageError })}>{message}</div>

            <div className="field">
              <div className="control has-icons-left">
                <input
                  placeholder="E-mail"
                  className="input"
                  name="email"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <span className="icon is-left">
                  <FontAwesomeIcon icon={faUser} />
                </span>
              </div>
              {errors.email && touched.email && <div className="help">{errors.email}</div>}
            </div>

            <div className="field">
              <div className="control has-icons-left">
                <input
                  placeholder="Password"
                  className="input"
                  name="password"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <span className="icon is-left">
                  <FontAwesomeIcon icon={faLock} />
                </span>
              </div>
              {errors.password && touched.password && <div className="help">{errors.password}</div>}
            </div>

            <div className="field is-grouped is-grouped-centered">
              <div className="control">
                <button className="button is-primary" type="submit">
                  Log in
                </button>
              </div>
            </div>

            <div className="field is-grouped is-grouped-centered">
              <div className="control">
                <GoogleButton />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </BasicViewContainer>
  );
};
