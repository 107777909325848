import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { configureStore } from '@reduxjs/toolkit';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';

import { Provider } from 'react-redux';
import { ScrollToTop } from '@src/routing/scroll-to-top';
import { LoginView } from '@src/views/auth/login/login-view';
import { SignupView } from '@src/views/auth/signup/signup-view';
import { SiteSubmissionView } from '@src/views/site-submission-view/site-submission-view';
import { AuthReducer } from '@src/actions/auth/reducer';
import { SiteView } from '@src/views/site-view/site-view';
import { LandingPage } from '@src/views/landing-page/landing-page';
import { CityView } from '@src/views/city-view/city-view';
import { FavoritesView } from '@src/views/favorites-view/favorites-view';
import { initializeApp } from '@src/initialize-app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GoogleAuthProvider } from '@src/auth/google-auth-context';
import { ErrorBoundary } from 'react-error-boundary';
import { About } from './views/about/about';
import { Footer, Loader } from './components';

import './index.scss';

const store = configureStore({
  reducer: {
    auth: AuthReducer,
  },
  middleware: [thunkMiddleware, promiseMiddleware],
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      suspense: true,
    },
  },
});

const ErrorFallback: React.FC = () => <div>Something wrong happened...</div>;

const App: React.FC = () => (
  <div className="app">
    <Provider store={store}>
      <GoogleAuthProvider>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <React.Suspense fallback={<Loader />}>
              <Router>
                <ScrollToTop>
                  <div className="app--inner-container">
                    <Routes>
                      <Route path="/about" element={<About />} />
                      <Route path="/login" element={<LoginView />} />
                      <Route path="/signup" element={<SignupView />} />
                      <Route path="/submit-site" element={<SiteSubmissionView />} />
                      <Route path="/" element={<LandingPage />} />
                      <Route path="/favorites" element={<FavoritesView />} />
                      <Route path="/:cityName" element={<CityView />} />
                      <Route path="/:cityName/:siteSlug" element={<SiteView />} />
                    </Routes>
                    <Footer />
                  </div>
                </ScrollToTop>
              </Router>
            </React.Suspense>
          </ErrorBoundary>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </GoogleAuthProvider>
    </Provider>
  </div>
);

initializeApp(store.dispatch);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
