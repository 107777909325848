import { createAction } from 'redux-actions';
import Storage from '@aws-amplify/storage';

export interface SiteSubmissionForm {
  siteName?: string;
  city?: string;
  photo?: {
    name: string;
    type: string;
  };
  siteDescription?: string;
  photoDescription?: string;
  email?: string;
  website?: string;
  author?: string;
}

export const uploadSiteSubmissionFormS3 = createAction(
  'SUBMIT_SITE',
  (submission: SiteSubmissionForm) => {
    const { city, siteName } = submission;
    const { photo, ...jsonToUpload } = submission;

    const submissionId = Math.floor(Math.random() * 1001);
    const photoExt = photo.name.substring(photo.name.lastIndexOf('.') + 1);

    const baseUploadName = `${city}_${siteName}_${submissionId}`;
    const photoUploadName = `${baseUploadName}/photo.${photoExt}`;

    return Storage.put(photoUploadName, photo, {
      contentType: photo.type,
    }).then(() => {
      const metadataUploadName = `${baseUploadName}/metadata.json`;

      return Storage.put(metadataUploadName, JSON.stringify(jsonToUpload), {
        contentType: 'application/json',
      });
    });
  },
);
