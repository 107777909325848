import {
  QueryClient,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import {
  addSiteToFavorites,
  removeSiteFromFavorites,
  SiteFavStatus,
} from '@src/queries/favorites-queries';

interface FavStatusTransformer {
  (oldFavStatus: SiteFavStatus): SiteFavStatus;
}

function createFavoritesMutationOpts(
  queryClient: QueryClient,
  favStatusTransformer: FavStatusTransformer,
): UseMutationOptions<unknown, unknown, string, { rollbackTo: SiteFavStatus }> {
  return {
    onMutate: async (siteId) => {
      await queryClient.cancelQueries(['favoriteStatus', siteId]);
      const oldFavStatus = queryClient.getQueryData<SiteFavStatus>(['favoriteStatus', siteId]);
      const newFavStatus = favStatusTransformer(oldFavStatus);
      queryClient.setQueryData(['favoriteStatus', siteId], newFavStatus);
      return { rollbackTo: oldFavStatus };
    },
    onError: (_err, _siteId, context) => {
      queryClient.setQueryData(['favoriteStatus'], context.rollbackTo);
    },
    onSettled: (_data, _err, siteId) => {
      queryClient.invalidateQueries(['favoriteStatus', siteId]);
      queryClient.invalidateQueries(['favoriteSites']);
    },
  };
}

const addToFavsStatusTransformer: FavStatusTransformer = (oldFavStatus: SiteFavStatus) => {
  return {
    isFavByUser: true,
    totalFavs: oldFavStatus.totalFavs + 1,
  };
};

const removeFromFavsStatusTransformer: FavStatusTransformer = (oldFavStatus: SiteFavStatus) => {
  return {
    isFavByUser: false,
    totalFavs: oldFavStatus.totalFavs - 1,
  };
};

export function useFavsMutations() {
  const queryClient = useQueryClient();

  const addToFavsMutation = useMutation(
    (siteId: string) => addSiteToFavorites(siteId),
    createFavoritesMutationOpts(queryClient, addToFavsStatusTransformer),
  );
  const removeFromFavsMutation = useMutation(
    (siteId: string) => removeSiteFromFavorites(siteId),
    createFavoritesMutationOpts(queryClient, removeFromFavsStatusTransformer),
  );

  return {
    addToFavsMutation,
    removeFromFavsMutation,
  };
}
