export const transformItem = (item) => {
  if (item instanceof Array) {
    return item.map((elem) => transformItem(elem));
  }
  if (typeof item === 'object') {
    if ('fields' in item) {
      const result = transformItem(item.fields);
      if ('sys' in item) {
        result.id = item.sys.id;
      }
      return result;
    }
    return Object.keys(item).reduce((mapped, key) => {
      // TODO: use reduce properly
      /* eslint-disable no-param-reassign */
      mapped[key] = transformItem(item[key]);
      return mapped;
    }, {});
  }
  return item;
};

export const transformContentfulResponse = (response) => response.items.map(transformItem);
