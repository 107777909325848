import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchSitesForCityAndSlugFromContentful } from '@src/api/contentful-api';
import { Header } from '../../components/header/header';
import { SiteDetails } from '../../components/site-details/site-details';
import { CityAndSiteRouteParams } from '../../routing/route-params';

import './site-view.scss';

export const SiteView: React.FC = () => {
  const params = useParams<CityAndSiteRouteParams>();

  const { cityName, siteSlug } = params;
  const { data: site } = useQuery(['site', cityName, siteSlug], () =>
    fetchSitesForCityAndSlugFromContentful(cityName, siteSlug),
  );

  return (
    <div className="site-view">
      <div className="header-container">
        <Header />
      </div>

      <div className="site-details-container">
        {site && <SiteDetails site={site} hideCloseButton inline />}
      </div>
    </div>
  );
};
