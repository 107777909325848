import ClipLoader from 'react-spinners/ClipLoader';
import * as React from 'react';
import { CSSProperties } from 'react';

interface Props {
  loading?: boolean;
  color?: string;
}

const cssOverride: CSSProperties = {
  position: 'fixed',
  margin: 'auto',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};

export const Loader: React.FC<Props> = ({ loading, color }) => (
  <ClipLoader loading={loading} cssOverride={cssOverride} color={color} />
);

Loader.defaultProps = {
  loading: true,
  color: undefined,
};
