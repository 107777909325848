import * as React from 'react';
import * as ReactDOM from 'react-dom';
import clsx from 'clsx';

import { PropsWithChildren, useEffect } from 'react';
import { HasClassName } from '@src/commons';

import './modal.scss';

type Props = PropsWithChildren & HasClassName;

export const Modal: React.FC<Props> = ({ children, className }) => {
  // This will be executed only on the initial render
  // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
  const [el] = React.useState(() => document.createElement('div'));

  useEffect(() => {
    const modalRoot = document.getElementById('modal');

    modalRoot.appendChild(el);
    document.body.classList.add('modal-open');

    return () => {
      modalRoot.removeChild(el);
      document.body.classList.remove('modal-open');
    };
  }, [el]);

  return ReactDOM.createPortal(
    <div className={clsx('modal-container', className)}>{children}</div>,
    el,
  );
};
