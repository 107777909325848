import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import { AnchorHTMLAttributes } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWikipediaW } from '@fortawesome/free-brands-svg-icons';

import styles from './site-details-description.module.scss';

interface Props {
  description: string;
}

const LinkRenderer = ({ href, title, children }: AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <a className={styles.markdownLink} href={href} target="_blank" rel="noreferrer">
    <span className={styles.quotationText}>{children}</span>
    {title && (
      <span>
        &nbsp;
        <span className={styles.quotationSource}>
          ({title === 'wikipedia' ? <FontAwesomeIcon icon={faWikipediaW as any} /> : title})
        </span>
      </span>
    )}
  </a>
);

export const SiteDetailsDescription: React.FC<Props> = ({ description }) => (
  <ReactMarkdown className={styles.siteDescription} components={{ a: LinkRenderer }}>
    {description}
  </ReactMarkdown>
);
