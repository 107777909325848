import * as React from 'react';
import { PropsWithChildren, useContext, useEffect, useState } from 'react';

const GOOGLE_CLIENT_ID = '70834098313-5tf0ni3tj9v173s90qk5stdi5lqhaf8s.apps.googleusercontent.com';

const GoogleAuthContext = React.createContext(null);

export function useGoogleAuth() {
  return useContext(GoogleAuthContext);
}

export const initializeGoogleAuth = () => {
  const script = document.createElement('script');
  script.src = 'https://apis.google.com/js/api:client.js';

  const loadedPromise = new Promise((resolve) => {
    script.onload = () => {
      const { gapi } = window as any;
      gapi.load('auth2', () => {
        gapi.auth2.init({ client_id: GOOGLE_CLIENT_ID }).then((auth2) => {
          resolve(auth2);
        });
      });
    };
  });

  document.body.appendChild(script);
  return loadedPromise;
};

export const GoogleAuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [googleAuth, setGoogleAuth] = useState(null);

  useEffect(() => {
    initializeGoogleAuth().then((googleAuthInstance) => {
      setGoogleAuth(googleAuthInstance);
    });
  }, []);

  return <GoogleAuthContext.Provider value={googleAuth}>{children}</GoogleAuthContext.Provider>;
};
