import * as React from 'react';

import { Header } from '@src/components/header/header';
import { PropsWithChildren } from 'react';

import styles from './page-with-header.module.scss';

export const PageWithHeader: React.FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.pageWithHeader}>
    <Header />
    <div className={styles.pageWithHeaderContainer}>{children}</div>
  </div>
);
