import * as React from 'react';

import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { City } from '@src/model';
import { useQuery } from '@tanstack/react-query';
import { fetchCities } from '@src/queries/city-queries';

import './trending-cities.scss';

const MAX_TRENDING_CITIES = 3;

const getTrendingCities = (allCities: City[]) => {
  // TODO: replace with a non-naive algorithm of choosing trending cities
  return allCities.slice(0, MAX_TRENDING_CITIES);
};

export const TrendingCities: React.FC = () => {
  const { data: cities } = useQuery(['cities'], () => fetchCities());
  const trendingCities = useMemo(() => getTrendingCities(cities), [cities]);

  return (
    <div className="trending-cities">
      <div className="trending-cities-header">🔥 Trending cities</div>
      <div className="trending-cities-list">
        {trendingCities.map((city) => (
          <div className="trending-cities-list-entry" key={city.slug}>
            <Link to={city.slug}>{city.name}</Link>
          </div>
        ))}
      </div>
    </div>
  );
};
