import Amplify from '@aws-amplify/core';

const AWS_REGION = 'eu-west-1';

export const PRIVATE_API_NAME = 'privateApi';

export const initializeAmplify = () => {
  Amplify.configure({
    Auth: {
      userPoolId: 'eu-west-1_aeKhrql4L',
      identityPoolId: 'eu-west-1:e15e2512-0ab2-485a-8801-9adbc62dddae',
      region: AWS_REGION,
      mandatorySignIn: false,
      userPoolWebClientId: 'o26ld5h7j02aaf3dtdvoqoabj',
    },
    API: {
      endpoints: [
        {
          name: PRIVATE_API_NAME,
          endpoint: 'https://wntbxxlbe7.execute-api.eu-west-1.amazonaws.com/dev',
          region: AWS_REGION,
        },
      ],
    },
    Storage: {
      bucket: 'submissions.tripspirations.com',
      region: AWS_REGION,
      identityPoolId: 'eu-west-1:e15e2512-0ab2-485a-8801-9adbc62dddae',
    },
  });
};
