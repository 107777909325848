import * as React from 'react';

import { PropsWithChildren } from 'react';
import { PageWithHeader } from '@src/components';

import styles from './basic-view-container.module.scss';

interface Props {
  heroImageUrl: string;
}

export const BasicViewContainer: React.FC<PropsWithChildren<Props>> = ({
  heroImageUrl,
  children,
}) => {
  return (
    <div className={styles.basicViewContainer}>
      <PageWithHeader>
        <div className={styles.imageAndChildrenContainer}>
          <div className={styles.heroImage} style={{ backgroundImage: `url(${heroImageUrl})` }} />
          <div className={styles.childrenContainer}>{children}</div>
        </div>
      </PageWithHeader>
    </div>
  );
};
