import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { PropsWithChildren, useEffect } from 'react';

// TODO: this probably should not be implemented with a HOC, rethink
export const ScrollToTop: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  /* eslint-disable react/jsx-no-useless-fragment */
  return <>{children}</>;
};
