import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import ClickOutside from 'react-click-outside';
import { ResourceLoadingStatus } from '@src/commons';
import { Loader } from '@src/components';
import { SiteDetailsSidebar } from './site-details-sidebar';
import { Site } from '../../model';

import styles from './site-details.module.scss';

interface Props {
  site: Site;
  onModalClose?: () => void;
  hideCloseButton?: boolean;
  inline?: boolean;
}

// TODO: we should probably remove the modal stylings from here
// and move it to modal.tsx

// Doesn't work very reliably. For some reason image cache expires very frequently and after a short while
function isImageCached(imageUrl: string) {
  const image = new Image();
  image.src = imageUrl;
  return image.complete;
}

export const SiteDetails: React.FC<Props> = ({ site, onModalClose, hideCloseButton, inline }) => {
  // TODO: uncomment when this feature is fixed
  // const [fullscreen, setFullscreen] = useState(false);
  const [fullscreen] = useState(false);

  const onImageClicked = useCallback(() => {
    // TODO: uncomment when this feature is fixed
    // setFullscreen((value) => !value);
  }, []);

  const sitePicture = useMemo(() => site.pictures[0], [site]);

  const [currentImageLoadingStatus, setCurrentImageLoadingStatus] = useState(
    ResourceLoadingStatus.NONE,
  );
  const onImageLoaded = useCallback(() => {
    setCurrentImageLoadingStatus(ResourceLoadingStatus.COMPLETE);
  }, []);

  const imageUrl = sitePicture.image.file.url;

  useEffect(() => {
    const loadingStatus = isImageCached(imageUrl)
      ? ResourceLoadingStatus.COMPLETE
      : ResourceLoadingStatus.PENDING;
    setCurrentImageLoadingStatus(loadingStatus);
  }, [imageUrl]);

  const isLoading = useMemo(
    () => currentImageLoadingStatus !== ResourceLoadingStatus.COMPLETE,
    [currentImageLoadingStatus],
  );

  /* TODO: uncomment the code below (if needed) and make it work correctly with zooming in/out */
  // const siteImageSize = window.matchMedia("(max-width: 480px)").matches ? SITE_IMAGE_SIZE_MOBILE : SITE_IMAGE_SIZE_DESKTOP;

  const className = clsx(styles.siteDetails, {
    [styles.siteDetailsDialog]: !inline,
  });

  const imageClassName = clsx([styles.imageContainer], {
    [styles.imageContainerFullscreen]: fullscreen,
  });

  const siteDetailsContentContainerClassName = clsx(styles.siteDetailsContentContainer, {
    [styles.siteDetailsContentContainerLoading]: isLoading,
    [styles.siteDetailsContentContainerInline]: inline,
  });

  return (
    <div className={className}>
      <Loader loading={isLoading} color="#fff" />

      <ClickOutside className={siteDetailsContentContainerClassName} onClickOutside={onModalClose}>
        <div className={imageClassName}>
          <img src={imageUrl} onClick={onImageClicked} onLoad={onImageLoaded} />
        </div>

        {!fullscreen && <SiteDetailsSidebar site={site} />}

        {!hideCloseButton && (
          <div className={styles.closeIcon} onClick={onModalClose}>
            x
          </div>
        )}
      </ClickOutside>
    </div>
  );
};

SiteDetails.defaultProps = {
  onModalClose: () => {},
  hideCloseButton: false,
  inline: false,
};
