import * as React from 'react';

import { signIn, signUp } from '@src/actions/auth/actions';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { GoogleButton } from '@src/components/google-button/google-button';
import { SchemaOf } from 'yup';
import { AppDispatch } from '@src/reducers';
import { useCallback, useState } from 'react';
import signupBackground from '@resources/images/signup-inspiration.jpg';
import { showNotification } from '@src/commons/notifications';
import { BasicViewContainer } from '@src/components/basic-view-container';

import styles from './signup-view.module.scss';

interface SignupForm {
  email: string;
  password: string;
}

const formInitialValues: SignupForm = {
  email: '',
  password: '',
};

const formValidationSchema: SchemaOf<SignupForm> = Yup.object().shape({
  email: Yup.string().email('E-mail is not valid!').required('E-mail is required!'),
  password: Yup.string()
    .min(6, 'Password has to be longer than 6 characters!')
    .required('Password is required!'),
});

export const SignupView: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [message, setMessage] = useState('');
  const [isMessageError, setMessageError] = useState(false);

  const onFormSubmit = useCallback(
    async ({ email, password }: SignupForm) => {
      try {
        await dispatch(signUp(email, password));
        await dispatch(signIn(email, password));

        showNotification('Successfully created account!');
        navigate('/');
      } catch (err) {
        setMessage(err.message);
        setMessageError(true);
      }
    },
    [dispatch, navigate],
  );

  return (
    <BasicViewContainer heroImageUrl={signupBackground}>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={onFormSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form className={styles.signupForm} onSubmit={handleSubmit}>
            <div className={styles.joinTripspirationsLabel}>Join Tripspirations</div>
            <div className={styles.alreadyHaveAccountLabel}>
              <span>Already have an account?</span>
              &nbsp;
              <Link to="/login">Login</Link>
            </div>
            <div className={clsx('message', { 'message-error': isMessageError })}>{message}</div>

            <div className="field">
              <div className="control has-icons-left">
                <input
                  placeholder="E-mail"
                  className="input"
                  name="email"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <span className="icon is-left">
                  <FontAwesomeIcon icon={faUser} />
                </span>
              </div>
              {errors.email && touched.email && <div className="help">{errors.email}</div>}
            </div>

            <div className="field">
              <div className="control has-icons-left">
                <input
                  placeholder="Password"
                  className="input"
                  name="password"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <span className="icon is-left">
                  <FontAwesomeIcon icon={faLock} />
                </span>
              </div>
              {errors.password && touched.password && <div className="help">{errors.password}</div>}
            </div>

            <div className="field is-grouped is-grouped-centered">
              <div className="control">
                <button className="button is-primary signup-button" type="submit">
                  Sign up
                </button>
              </div>
            </div>

            <div className="field is-grouped is-grouped-centered">
              <div className="control">
                <GoogleButton />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </BasicViewContainer>
  );
};
