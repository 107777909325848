import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';

// Modal root is the underlying view on top of which modals can be displayed
const ModalRootContext = React.createContext({
  href: null,
});

interface ModalRootProviderProps {
  href: string;
}

export const ModalRootProvider: React.FC<PropsWithChildren<ModalRootProviderProps>> = ({
  href,
  children,
}) => {
  const value = useMemo(
    () => ({
      href,
    }),
    [href],
  );

  return <ModalRootContext.Provider value={value}>{children}</ModalRootContext.Provider>;
};

export const useModalRootHref = () => {
  const href = React.useContext(ModalRootContext)?.href;
  if (!href) {
    throw Error('Trying to get modal root href without the corresponding <ModalRootProvider>.');
  }
  return href;
};
