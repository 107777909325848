import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ESC_KEYCODE } from '@src/commons/keycodes';
import { useModalRootHref } from '@src/modal-root-context';
import { replaceUrlOutsideOfRR } from '@src/routing/routing-utils';
import { Site } from '../../model';
import { SiteDetails } from '../site-details/site-details';
import { Modal } from '../dialog/modal';
import { SitesGallery } from './sites-gallery';

import './sites-gallery.scss';

interface Props {
  sites: Site[];
}

// This is a component wrapping SitesGallery and providing support for displaying a modal
// presenting the site selected (+ handling its loading state)
// TODO: think if the name is appropriate. Maybe: SitesGalleryModalManager?
export const SitesGalleryWithModal: React.FC<Props> = ({ sites }) => {
  const navigate = useNavigate();
  const modalRootHref = useModalRootHref();

  const [siteInModal, setSiteInModal] = useState(null);

  const navigateToSite = useCallback((site: Site) => {
    // TODO: probably this component is not the best place for this routing logic
    // Instead we can do it on action level: https://github.com/ReactTraining/react-router/issues/3498

    // Routing to site URL without triggering route change to keep the underlying view in the background
    replaceUrlOutsideOfRR(`/${site.location.slug}/${site.slug}`);
    // Opening a modal on top of the current view
    setSiteInModal(site);
  }, []);

  const closeModalAndNavigateBack = useCallback(() => {
    setSiteInModal(null);

    // Routing back to the url of the current underlying view (modal root)
    navigate(modalRootHref);
  }, [navigate, modalRootHref]);

  const handleEscPressed = useCallback(
    (evt) => {
      if (evt.keyCode === ESC_KEYCODE) {
        closeModalAndNavigateBack();
      }
    },
    [closeModalAndNavigateBack],
  );

  useEffect(() => {
    // TODO: Is there a way to do it on the component-level, without accessing global document object?
    document.addEventListener('keydown', handleEscPressed);

    return () => {
      document.removeEventListener('keydown', handleEscPressed);
    };
  });

  return (
    <>
      {siteInModal && (
        <Modal>
          <SiteDetails site={siteInModal} onModalClose={closeModalAndNavigateBack} />
        </Modal>
      )}

      <SitesGallery sites={sites} onSiteClicked={(site) => navigateToSite(site)} />
    </>
  );
};
