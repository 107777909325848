import * as React from 'react';
import { Link } from 'react-router-dom';

import tripspirationsLogoSrc from '@resources/logos/tripspirations-logo.png';

import { SearchBar } from '@src/components';
import { UserHeaderMenu } from '@src/components/header/user-header-menu/user-header-menu';
import { MainHeaderMenu } from './main-header-menu';

import styles from './header.module.scss';

type Props = {
  hideSearch?: boolean;
};

export const Header: React.FC<Props> = ({ hideSearch }) => {
  return (
    <header className={styles.header}>
      <div className={styles.headerInner}>
        <Link to="/">
          <img className={styles.headerInnerLogo} src={tripspirationsLogoSrc} />
        </Link>

        {!hideSearch && <SearchBar className={styles.headerInnerSearch} />}

        <div className={styles.headerInnerPanel}>
          <UserHeaderMenu />
          <MainHeaderMenu />
        </div>
      </div>
    </header>
  );
};

Header.defaultProps = {
  hideSearch: false,
};
