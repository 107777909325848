import * as React from 'react';

import { useAuth } from '@src/auth';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faPaperPlane,
  faEnvelope,
  faLightbulb,
  faSignIn,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';

import { HeaderMenu } from '@src/components/header/header-menu';

import styles from '../header-menu/header-menu.module.scss';

const MainHeaderMenuIcon: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <FontAwesomeIcon className={styles.headerMenuIcon} icon={faBars} onClick={onClick} />
);

export const MainHeaderMenu: React.FC = () => {
  const { username, authPending } = useAuth();

  return (
    <HeaderMenu ClickableIcon={MainHeaderMenuIcon}>
      <Link className={styles.headerMenuInnerLink} to="/about">
        <FontAwesomeIcon className={styles.headerMenuInnerLinkIcon} icon={faPaperPlane} />
        About
      </Link>

      <a
        className={styles.headerMenuInnerLink}
        href="mailto:sobczyk.peter@gmail.com?Subject=Tripspirations"
        target="_top"
      >
        <FontAwesomeIcon className={styles.headerMenuInnerLinkIcon} icon={faEnvelope} />
        Contact us
      </a>

      <Link className={styles.headerMenuInnerLink} to="/submit-site">
        <FontAwesomeIcon className={styles.headerMenuInnerLinkIcon} icon={faLightbulb} />
        Suggest a place
      </Link>

      {!authPending && !username && (
        <div className={styles.headerMenuInnerPanel}>
          <Link className={styles.headerMenuInnerPanelLink} to="/login">
            <FontAwesomeIcon className={styles.headerMenuInnerPanelLinkIcon} icon={faSignIn} />
            Log in
          </Link>
          <Link className={styles.headerMenuInnerPanelLink} to="/signup">
            <FontAwesomeIcon className={styles.headerMenuInnerPanelLinkIcon} icon={faUserPlus} />
            Sign up
          </Link>
        </div>
      )}
    </HeaderMenu>
  );
};
