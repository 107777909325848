import * as React from 'react';
import Autosuggest from 'react-autosuggest';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { HasClassName } from '@src/commons/common-types';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchCities } from '@src/queries/city-queries';
import { City } from '@src/model';

import styles from './search-bar.module.scss';

const getSuggestionValue = (suggestedLocation: City) => suggestedLocation.name;

const renderSuggestion = (suggestion: City, { isHighlighted }) => {
  const className = clsx('suggestion', {
    'suggestion-selected': isHighlighted,
  });

  return <div className={className}>{suggestion.name}</div>;
};

export const SearchBar: React.FC<HasClassName> = ({ className }) => {
  const navigate = useNavigate();

  const [suggestedCities, setSuggestedCities] = useState<City[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const { data: cities } = useQuery(['cities'], () => fetchCities());

  const findSuggestedCities = useCallback(
    (searchTerm: string) => {
      const normalizedSearchTerm = searchTerm.trim().toLowerCase();
      const inputLength = normalizedSearchTerm.length;

      return inputLength === 0
        ? []
        : cities.filter(
            (city) => city.name.toLowerCase().slice(0, inputLength) === normalizedSearchTerm,
          );
    },
    [cities],
  );

  const onSuggestionsFetchRequested = useCallback(
    ({ value }) => {
      setSuggestedCities(findSuggestedCities(value));
    },
    [findSuggestedCities, setSuggestedCities],
  );

  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestedCities([]);
  }, [setSuggestedCities]);

  const onChange = useCallback(
    (_event: any, { newValue }) => {
      setSearchValue(newValue);
    },
    [setSearchValue],
  );

  const onSuggestionSelected = useCallback(
    (_event: any, { suggestion }) => {
      setSearchValue('');
      navigate(`/${suggestion.slug}`);
    },
    [navigate],
  );

  const inputProps = useMemo(
    () => ({
      placeholder: 'Search for city or place',
      value: searchValue,
      onChange,
    }),
    [onChange, searchValue],
  );

  return (
    <div className={clsx(styles.searchBar, className)}>
      <Autosuggest
        suggestions={suggestedCities}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
        highlightFirstSuggestion
      />
    </div>
  );
};
