import * as React from 'react';
import { City } from '../../model';
import {
  LOCATION_IMAGE_SIZE_DESKTOP,
  LOCATION_IMAGE_SIZE_MOBILE,
} from '../../images/images-consts';
import { LazyImg } from '../lazy-img/lazy-img';

import styles from './city-image.module.scss';

interface Props {
  city: City;
  imageClass: string;
}

export const CityImage: React.FC<Props> = ({ city, imageClass }) => {
  // TODO: does it have to be done from JS?
  const locationImageSize = window.matchMedia('screen and (max-width: 480px)').matches
    ? LOCATION_IMAGE_SIZE_MOBILE
    : LOCATION_IMAGE_SIZE_DESKTOP;

  return (
    <div className={styles.cityImage}>
      <LazyImg src={city.picture.file.url} imageWidth={locationImageSize} className={imageClass} />

      <div className={styles.cityName}>{city.name}</div>
    </div>
  );
};
