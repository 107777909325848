import * as React from 'react';

import { useDispatch } from 'react-redux';
import { AppDispatch } from '@src/reducers';
import { GoogleSignInData, signInFromGoogle } from '@src/actions/auth/actions';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import googleButtonImage from '@resources/images/btn_google_signin_dark_focus_web@2x.png';
import { useGoogleAuth } from '@src/auth/google-auth-context';

import styles from './google-button.module.scss';

export const GoogleButton: React.FC = () => {
  const buttonRef = useRef();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const googleAuth = useGoogleAuth();

  useEffect(() => {
    if (googleAuth) {
      googleAuth.attachClickHandler(buttonRef.current, {}, async (googleUser) => {
        const authResponse = googleUser.getAuthResponse();
        const profile = googleUser.getBasicProfile();

        const googleSignInData: GoogleSignInData = {
          name: profile.getName(),
          email: profile.getEmail(),
          token: authResponse.id_token,
          expires_at: authResponse.expires_at,
        };

        await dispatch(signInFromGoogle(googleSignInData));
        navigate('/');
      });
    }
  }, [googleAuth, dispatch, navigate]);

  return (
    <div
      className={styles.googleSignInButton}
      ref={buttonRef}
      style={{ backgroundImage: `url(${googleButtonImage})` }}
    />
  );
};
