import { getFromApi } from '@src/api/rest-api';
import API from '@aws-amplify/api';
import { PRIVATE_API_NAME } from '@src/aws/amplify';
import { fetchSitesByIdFromContentful } from '@src/api/contentful-api';

export interface SiteFavStatus {
  isFavByUser: boolean;
  totalFavs: number;
}

export function fetchInFavsStatusForSiteId(siteId: string): Promise<SiteFavStatus> {
  return getFromApi(`/favorites/${siteId}`);
}

export function addSiteToFavorites(siteId: string): Promise<void> {
  const request = {
    body: { siteId },
  };
  return API.post(PRIVATE_API_NAME, '/favorites', request);
}

export function removeSiteFromFavorites(siteId: string): Promise<void> {
  const request = {
    body: { siteId },
  };
  return API.del(PRIVATE_API_NAME, '/favorites', request);
}

export const fetchUserFavoriteSites = () =>
  getFromApi('/favorites').then((favorites: { siteId: string }[]) => {
    const favoriteSitesIds = favorites.map((favorite) => favorite.siteId);
    return fetchSitesByIdFromContentful(favoriteSitesIds);
  });
