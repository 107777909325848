import * as React from 'react';
import { LazyImage, LazyImageRenderPropArgs, RefArg } from 'react-lazy-images';
import './lazy-img.scss';
import clsx from 'clsx';
import { useCallback } from 'react';
import { imageUrlForSize, lowQualityImageUrlForSize } from '../../images/image-utils';

interface LazyImgProps {
  src: string;
  imageWidth: number;
  className?: string;
}

type PlaceholderProps = LazyImageRenderPropArgs & RefArg;

// This implementation's strong point is that the full version of an image is only started to be loaded
// after the image is visible on screen which means lower number of requests for initial page load and completely
// omitting requests for images that were never scrolled to.
export const LazyImg: React.FC<LazyImgProps> = ({ src, imageWidth, className }: LazyImgProps) => {
  const renderPlaceholder = useCallback(
    ({ imageProps, ref }: PlaceholderProps) => (
      <img
        className={clsx('lazy-img', 'blurred', className)}
        ref={ref}
        src={lowQualityImageUrlForSize(src, imageWidth)}
        alt={imageProps.alt}
      />
    ),
    [className, src, imageWidth],
  );

  const renderActual = useCallback(
    ({ imageProps }: LazyImageRenderPropArgs) => (
      <img className={clsx('lazy-img', className)} {...imageProps} />
    ),
    [className],
  );

  return (
    <LazyImage
      debounceDurationMs={100}
      src={imageUrlForSize(src, imageWidth)}
      placeholder={renderPlaceholder}
      actual={renderActual}
    />
  );
};

LazyImg.defaultProps = {
  className: '',
};
