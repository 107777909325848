import { AnyAction } from 'redux';
import { AuthModel } from '@src/auth/auth-model';
import { signIn, signInFromGoogle } from './actions';

const initialState: AuthModel = {
  username: null,
  authPending: false,
  signedInFromGoogle: false,
};

export const AuthReducer = (state = initialState, action: AnyAction): AuthModel => {
  switch (action.type) {
    case `${signIn}_PENDING`:
      return {
        ...state,
        username: null,
        authPending: true,
      };
    case `${signIn}_FULFILLED`:
      return {
        ...state,
        username: action.payload,
        authPending: false,
        signedInFromGoogle: false,
      };
    case `${signIn}_REJECTED`:
      return {
        ...state,
        username: null,
        authPending: false,
      };
    case `${signInFromGoogle}_PENDING`:
      return {
        ...state,
        username: null,
        authPending: true,
      };
    case 'SIGN_IN_FROM_GOOGLE_FULFILLED':
      return {
        ...state,
        username: action.payload,
        authPending: false,
        signedInFromGoogle: true,
      };
    case `${signInFromGoogle}_REJECTED`:
      return {
        ...state,
        username: null,
        authPending: false,
      };
    case 'MARK_LOGGED_USER_AS_FROM_GOOGLE_FULFILLED':
      return {
        ...state,
        signedInFromGoogle: true,
      };
    case 'SIGN_OUT_FULFILLED':
      return {
        ...state,
        username: null,
        signedInFromGoogle: false,
      };
    default:
      return state;
  }
};
