import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartOutline } from '@fortawesome/free-regular-svg-icons';
import { tripadvisorUrl, wikipediaUrl } from '@src/integrations/services_urls';
import { Site } from '@src/model';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchInFavsStatusForSiteId, SiteFavStatus } from '@src/queries/favorites-queries';

import taLogoSrc from '@resources/logos/ta-logo.png';
import wikipediaLogoSrc from '@resources/logos/wikipedia-logo.png';
import gmapsLogoSrc from '@resources/logos/gmaps-logo.png';

import { BeatLoader } from 'react-spinners';
import { useFavsMutations } from '@src/queries/hooks';

import { useAuth } from '@src/auth';
import styles from './site-details-favorites-section.module.scss';

interface Props {
  site: Site;
}

interface FavsIconProps {
  site: Site;
  favStatus: SiteFavStatus;
}

const FavsIndicator: React.FC<FavsIconProps> = ({ site, favStatus }) => {
  const { isFavByUser: isSiteInFavs, totalFavs } = favStatus;

  const { addToFavsMutation, removeFromFavsMutation } = useFavsMutations();
  const addedToFavsWasFired = useMemo(() => !addToFavsMutation.isIdle, [addToFavsMutation.isIdle]);

  const favsIconClicked = useCallback(() => {
    if (addToFavsMutation.isLoading || removeFromFavsMutation.isLoading) {
      return;
    }

    if (!isSiteInFavs) {
      addToFavsMutation.mutate(site.id);
    } else {
      removeFromFavsMutation.mutate(site.id);
    }
  }, [site, isSiteInFavs, addToFavsMutation, removeFromFavsMutation]);

  const favsIconClassName = clsx(styles.heartIconContainer, {
    [styles.heartIconContainerAddedToFavorites]: isSiteInFavs && addedToFavsWasFired,
  });

  return (
    <>
      <span className={favsIconClassName} onClick={favsIconClicked}>
        <FontAwesomeIcon icon={isSiteInFavs ? faHeart : faHeartOutline} />
      </span>
      <span>{totalFavs}</span>
    </>
  );
};

export const SiteDetailsFavoritesSection: React.FC<Props> = ({ site }) => {
  const { username } = useAuth();
  const enableFavorites = !!username;

  // TODO: try to introduce suspense
  const { data: favsStatus, isLoading: isSiteInFavsLoading } = useQuery(
    ['favoriteStatus', site.id],
    () => fetchInFavsStatusForSiteId(site.id),
    {
      suspense: false,
      enabled: enableFavorites,
    },
  );

  const anyLinksAvailable = useMemo(() => {
    return site.googleMapsUrl || site.wikipediaSlug || site.tripadvisorId;
  }, [site]);

  if (!enableFavorites && !anyLinksAvailable) {
    return null;
  }

  return (
    <div className={styles.favoritesSection}>
      <div className={styles.heartAndNumberContainer}>
        {enableFavorites && (
          <>
            {isSiteInFavsLoading && <BeatLoader color="#538fef" />}

            {!isSiteInFavsLoading && <FavsIndicator site={site} favStatus={favsStatus} />}
          </>
        )}
      </div>

      {(site.googleMapsUrl || site.wikipediaSlug || site.tripadvisorId) && (
        <div className={styles.servicesLinksContainer}>
          {site.googleMapsUrl && (
            <a href={site.googleMapsUrl} target="_blank" rel="noreferrer">
              <img className={styles.logo} src={gmapsLogoSrc} />
            </a>
          )}
          {site.wikipediaSlug && (
            <a href={wikipediaUrl(site.wikipediaSlug)} target="_blank" rel="noreferrer">
              <img className={styles.logo} src={wikipediaLogoSrc} />
            </a>
          )}
          {site.tripadvisorId && (
            <a href={tripadvisorUrl(site.tripadvisorId)} target="_blank" rel="noreferrer">
              <img className={styles.logo} src={taLogoSrc} />
            </a>
          )}
        </div>
      )}
    </div>
  );
};
