import * as contentful from 'contentful';
import { transformContentfulResponse } from '../model/model_converter';
import { Site } from '../model';

const contentfulClient = contentful.createClient({
  space: '678amhyfm3nh',
  accessToken: process.env.CONTENTFUL_API_KEY,
});

export const fetchCityFromContentful = (citySlug: string) =>
  contentfulClient
    .getEntries({
      content_type: 'location',
      'fields.slug': citySlug,
    })
    .then((response) => {
      const citiesList = transformContentfulResponse(response);
      return citiesList && citiesList.length > 0 ? citiesList[0] : undefined;
    });

export const fetchCitiesFromContentful = () =>
  contentfulClient
    .getEntries({
      content_type: 'location',
    })
    .then((response) => transformContentfulResponse(response));

export const fetchAllSitesFromContentful = () =>
  contentfulClient
    .getEntries({
      content_type: 'site',
    })
    .then((response) => transformContentfulResponse(response));

export const fetchSitesByIdFromContentful = (siteIds: string[]) => {
  const siteIdsQuery = siteIds.join(',');
  return contentfulClient
    .getEntries({
      content_type: 'site',
      'sys.id[in]': siteIdsQuery,
    })
    .then((response) => transformContentfulResponse(response));
};

export const fetchSitesForCityFromContentful = (citySlug: string): Promise<Site[]> =>
  contentfulClient
    .getEntries({
      content_type: 'site',
      'fields.location.sys.contentType.sys.id': 'location',
      'fields.location.fields.slug': citySlug,
    })
    .then((response) => transformContentfulResponse(response));

export const fetchSitesForCityAndSlugFromContentful = (
  citySlug: string,
  siteSlug: string,
): Promise<Site> =>
  contentfulClient
    .getEntries({
      content_type: 'site',
      'fields.location.sys.contentType.sys.id': 'location',
      'fields.location.fields.slug': citySlug,
      'fields.slug': siteSlug,
    })
    .then((response) => {
      const sitesList = transformContentfulResponse(response);
      return sitesList && sitesList.length > 0 ? sitesList[0] : undefined;
    });
