import * as React from 'react';
import { Site } from '@src/model';
import { Link } from 'react-router-dom';
import { SiteDetailsDescription, SiteDetailsFavoritesSection } from '@src/components';
import { useMemo } from 'react';
import clsx from 'clsx';

import styles from './site-details-sidebar.module.scss';

type Props = {
  site: Site;
};

export const SiteDetailsSidebar: React.FC<Props> = ({ site }) => {
  const sitePicture = useMemo(() => site.pictures[0], [site]);
  return (
    <div className={styles.siteDetailsSidebarContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <h2 className={clsx(styles.titleContainerRow, styles.titleContainerRowTitle)}>
            {site.primaryName}
          </h2>
          {site.secondaryName && (
            <div className={clsx(styles.titleContainerRow, styles.titleContainerRowLocalizedTitle)}>
              {site.secondaryName}
            </div>
          )}
          <div className={clsx(styles.titleContainerRow, styles.titleContainerRowCity)}>
            <Link to={`/${site.location.slug}`}>{site.location.name}</Link>
          </div>
        </div>
      </div>

      <SiteDetailsFavoritesSection site={site} />

      <SiteDetailsDescription description={site.description} />

      {(sitePicture.photographer || sitePicture.description) && (
        <div className={styles.photoDetailsContainer}>
          <div className={styles.leftArrowContainer}>
            <div className={styles.leftArrowSymbol} />
          </div>
          <div className={styles.photoDetailsInnerContainer}>
            {sitePicture.photographer && (
              <div className={styles.photoCredits}>
                <span>Author:&nbsp;</span>
                {sitePicture.website ? (
                  // TODO: is a class needed here?
                  <a href={sitePicture.website} target="_blank" rel="noreferrer">
                    {sitePicture.photographer}
                  </a>
                ) : (
                  <span>{sitePicture.photographer}</span>
                )}
              </div>
            )}
            {sitePicture.description && (
              <div className={styles.photoDescription}>{sitePicture.description}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
