import Auth from '@aws-amplify/auth';
import { createAction } from 'redux-actions';

type AwsUser = {
  email?: string;
  attributes?: {
    email: string;
  };
};

export type GoogleSignInData = {
  name: string;
  email: string;
  token: string;
  expires_at: number;
};

const extractEmail = (user: AwsUser) => (user.email ? user.email : user.attributes.email);

export const signIn = createAction<Promise<string>, string, string>(
  'SIGN_IN',
  async (username: string, password: string) => {
    const user = await Auth.signIn(username, password);
    return user.attributes.email;
  },
);

export const signInFromGoogle = (signInData: GoogleSignInData) => (dispatch, getState) => {
  const federatedSignInPromise = Auth.federatedSignIn(
    'google',
    {
      token: signInData.token,
      expires_at: signInData.expires_at,
    },
    { email: signInData.email, name: signInData.name },
  );

  const currentLoggedUsername = getState().auth.username;
  if (currentLoggedUsername === signInData.email) {
    return dispatch({
      type: 'MARK_LOGGED_USER_AS_FROM_GOOGLE',
      payload: federatedSignInPromise,
    });
  }
  return dispatch({
    type: 'SIGN_IN_FROM_GOOGLE',
    payload: federatedSignInPromise.then(() => Promise.resolve(signInData.email)),
  });
};

export const signInIfSessionActive = createAction(
  `${signIn}`,
  // Empty catch because failing to log in to the current session is an expected behavior
  () =>
    Auth.currentAuthenticatedUser()
      .then(extractEmail)
      .catch(() => {}),
);

export const signOut = (googleAuth: any) => (dispatch, getState) => {
  const { signedInFromGoogle } = getState().auth;

  const promise = signedInFromGoogle
    ? Auth.signOut().then(() => googleAuth.signOut())
    : Auth.signOut();

  return dispatch({
    type: 'SIGN_OUT',
    payload: promise,
  });
};

export const signUp = createAction<Promise<any>, string, string>(
  'SIGN_UP',
  async (email: string, password: string) =>
    Auth.signUp({
      username: email,
      password,
    }),
);
