import * as React from 'react';
import ClickOutside from 'react-click-outside';
import { PropsWithChildren, useCallback, useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import styles from './header-menu.module.scss';

type Props = {
  ClickableIcon: React.ComponentType<{ onClick: () => void }>;
};

export const HeaderMenu: React.FC<PropsWithChildren<Props>> = ({ ClickableIcon, children }) => {
  const [menuIsOpened, setMenuIsOpened] = useState(false);
  const onMenuIsOpened = useCallback(() => {
    setMenuIsOpened(true);
  }, []);

  const onMenuIsClosed = useCallback(() => {
    setMenuIsOpened(false);
  }, []);

  return (
    <>
      <ClickableIcon onClick={onMenuIsOpened} />
      <div
        className={clsx(styles.overlay, {
          [styles.isOpened]: menuIsOpened,
          [styles.isClosed]: !menuIsOpened,
        })}
      >
        <ClickOutside className={styles.headerMenuInner} onClickOutside={onMenuIsClosed}>
          <FontAwesomeIcon
            className={styles.headerMenuInnerClose}
            icon={faClose}
            onClick={onMenuIsClosed}
          />

          {children}
        </ClickOutside>
      </div>
    </>
  );
};
