import * as React from 'react';

import { ImageOverlay } from '../overlay/image-overlay';
import { Site } from '../../model';
import { SITE_IMAGE_SIZE_DESKTOP, SITE_IMAGE_SIZE_MOBILE } from '../../images/images-consts';
import { LazyImg } from '../lazy-img/lazy-img';

import './sites-gallery.scss';

interface Props {
  sites: Site[];
  onSiteClicked: (Site) => void;
}

export const SitesGallery: React.FC<Props> = ({ sites, onSiteClicked }) => {
  const siteImageSize = window.matchMedia('screen and (max-width: 480px)').matches
    ? SITE_IMAGE_SIZE_MOBILE
    : SITE_IMAGE_SIZE_DESKTOP;

  return (
    <div className="gallery">
      <div className="col-layout-images-container">
        {sites.map((site) => (
          <div
            key={site.primaryName}
            className="overlay-image-container"
            onClick={() => onSiteClicked(site)}
          >
            <LazyImg
              src={site.pictures[0].image.file.url}
              imageWidth={siteImageSize}
              className="col-layout-image"
            />

            <ImageOverlay className="overlay" site={site} />
          </div>
        ))}
      </div>
    </div>
  );
};
