import * as React from 'react';
import { Link } from 'react-router-dom';

import styles from './footer.module.scss';

export const Footer: React.FC = () => (
  <div className={styles.footer}>
    <div className={styles.footerLinks}>
      <Link className={styles.footerLinksElement} to="/about">
        About
      </Link>
      <a
        className={styles.footerLinksElement}
        href="mailto:sobczyk.peter@gmail.com?Subject=Tripspirations"
        target="_top"
      >
        Contact us
      </a>
    </div>
    <div className={styles.footerCopyright}>© 2022 Tripspirations</div>
  </div>
);
