import * as React from 'react';
import { BasicViewContainer } from '@src/components/basic-view-container';

import aboutBackground from '@resources/images/about-inspiration.jpg';
import styles from './about.module.scss';

export const About: React.FC = () => (
  <BasicViewContainer heroImageUrl={aboutBackground}>
    <div className={styles.contentContainer}>
      <p>
        Tripspirations is a place in the web where travelers looking for inspiration for their next
        trip discover places they would love visit.
      </p>

      <p>
        There is tons of great photography around the web but it is hard to find. Time to put an end
        to it. Our idea is to link the most interesting locations around the world with beautiful
        pictures.
      </p>
    </div>
  </BasicViewContainer>
);
