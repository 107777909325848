import * as React from 'react';

import clsx from 'clsx';
import { useMemo } from 'react';
import { useAuth } from '@src/auth';

import './user-icon.scss';

interface Props {
  className?: string;
  onClick?: () => void;
}

export const UserIcon: React.FC<Props> = ({ className, onClick }) => {
  const { username } = useAuth();

  const userLetter = useMemo(() => {
    if (username && username.length > 0) {
      return username[0];
    }
    return '';
  }, [username]);

  return (
    <div className={clsx('user-icon', className)} onClick={() => onClick()}>
      <span className="user-icon-letter">{userLetter}</span>
    </div>
  );
};

UserIcon.defaultProps = {
  className: '',
  onClick: () => {},
};
