import * as React from 'react';
import clsx from 'clsx';

import { Header } from '@src/components/header/header';
import { ModalRootProvider } from '@src/modal-root-context';
import introBackground from '@resources/images/intro-background.jpg';
import { TrendingCities } from '@src/components/trending-cities';
import { ENABLE_TRENDING_CITIES } from '@src/commons/feature-flags';
import { useQuery } from '@tanstack/react-query';
import { fetchAllSites } from '@src/queries/sites-queries';
import { SearchBar } from '@src/components/search-bar/search-bar';
import { SitesGalleryWithModal } from '@src/components/sites-gallery/sites-gallery-with-modal';

import styles from './landing-page.module.scss';

export const LandingPage: React.FC = () => {
  const { data: sites } = useQuery(['sites'], fetchAllSites);

  return (
    <ModalRootProvider href="/">
      <div className={styles.landingPage}>
        <Header hideSearch />

        {ENABLE_TRENDING_CITIES && <TrendingCities />}

        <div
          className={styles.heroContainer}
          style={{ backgroundImage: `url(${introBackground})` }}
        >
          <div className={styles.heroBox}>
            <div className={clsx(styles.heroBoxSection, styles.siteTitle)}>Tripspirations</div>
            <div className={clsx(styles.heroBoxSection, styles.siteDescription)}>
              Photography that inspires to travel
            </div>
            {/* TODO does it have to be two separate classes? */}
            <SearchBar className={styles.searchBar} />
          </div>
        </div>

        <div className={styles.contentContainer}>
          <div className={styles.sitesListTitle}>Some great places to inspire you!</div>
          <SitesGalleryWithModal sites={sites} />
        </div>
      </div>
    </ModalRootProvider>
  );
};
