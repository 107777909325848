import * as React from 'react';
import { SitesGalleryWithModal } from '@src/components/sites-gallery/sites-gallery-with-modal';
import { Link } from 'react-router-dom';
import { Header } from '@src/components/header/header';
import { ModalRootProvider } from '@src/modal-root-context';
import { useQuery } from '@tanstack/react-query';
import { fetchUserFavoriteSites } from '@src/queries/favorites-queries';

import styles from './favorites-view.module.scss';

export const FavoritesView: React.FC = () => {
  const { data: favoriteSites } = useQuery(['favoriteSites'], () => fetchUserFavoriteSites());

  return (
    <ModalRootProvider href="/favorites">
      <div className={styles.favoritesView}>
        <Header />

        <div className={styles.contentContainer}>
          {favoriteSites.length > 0 ? (
            <SitesGalleryWithModal sites={favoriteSites} />
          ) : (
            <div className={styles.specialMessageContainer}>
              <div>
                <span>No favorites yet, </span>
                <Link to="/">browse some more places</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalRootProvider>
  );
};
