import {
  fetchAllSitesFromContentful,
  fetchSitesForCityFromContentful,
} from '@src/api/contentful-api';
import { Site } from '@src/model';

export const fetchAllSites = (): Promise<Site[]> => fetchAllSitesFromContentful();

export const fetchSitesForCity = (citySlug: string): Promise<Site[]> =>
  fetchSitesForCityFromContentful(citySlug);
